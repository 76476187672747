import { memo, useMemo, useContext } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router'
import PropsContext from 'context/props'
import oc from 'open-color'
import Button, { ButtonProps } from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import ReplayIcon from '@material-ui/icons/Replay'
import { restartSurvey } from 'container/Survey/Live/Ending'

interface Props {
    isView: boolean;
}

const useStyles = makeStyles(theme => ({
    button: {
        position: 'fixed',
        zIndex: 10,
        [theme.breakpoints.up('md')]: {
            width: 'initial',
            top: 0,
            right: 0,
            bottom: 'unset',
            left: 'unset',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            boderBottomLeftRadius: 3,
            backgroundColor: oc.gray[2]
        },
        [theme.breakpoints.down('md')]: {
            top: 'unset',
            right: 'unset',
            bottom: 0,
            left: 0,
            width: '100%',
            borderTop: `1px solid ${oc.gray[1]}`,
            borderRadius: 0,
            backgroundColor: '#fafafa'
        },
        '&:hover': {
            backgroundColor: oc.gray[3]
        }
        
    }
}))

const MyButton = withStyles(theme => ({
    
}))((props: ButtonProps) => <Button  size="small" {...props}/>)

const MyTypography = withStyles(theme => ({
    root: ({ font_family }:{font_family: string}) => {
        return {
            fontFamily: font_family,
            fontSize: 12,
            [theme.breakpoints.down('md')]: {
                fontSize: 16,
                height: 30
            }
        }
    }
}))(Typography)

export function isOffline(pathname: string) {
    return /^\/ro\//.test(pathname)
}

function RestartComponent(props: Props) {
    const { isView } = props
    const { survey_no, design }:any = useContext(PropsContext)

    const { formatMessage: f } = useIntl()
  
    const { font_family } = design

    const classes = useStyles()
    
    const location = useLocation()
    const { pathname } = location

    return (
        <>
        {
            useMemo(() => {
                return (isOffline(pathname) && isView) ? (
                    <MyButton
                        size="small" 
                        startIcon={<ReplayIcon color="action" fontSize="small"/>}
                        className={classes.button}
                        onClick={() => {
                            restartSurvey({ survey_no, pathname })
                        }}
                    >
                        <MyTypography font_family={font_family} variant="button">{f({id: 'component.Survey.Live.Restart.button'})}</MyTypography>
                    </MyButton>
                ) : null
            }, [survey_no, pathname, font_family, isView, classes.button, f])
        }
        </>
    )
}

export default memo(RestartComponent)