import { useContext } from 'react'
import { useSelector } from 'react-redux'
import PropsContext from 'context/props'
import { getReply, getComment } from 'utils/survey'
import { SurveyLiveQuestionComponent, SurveyLiveCautionComponent, SurveyLivePageMultiComponent } from 'component'
import { WrapperStyled, QuestionStyled } from 'styled/Survey/Live/Multi'
import TopLogoComponent from './TopLogo'
import PageMultiComponent from './PageMulti'

export default () => {
    const { survey_no, mbox, questions, replys, replyComments } = useContext(PropsContext)
 
    const backdrop = useSelector(state => state.backdrop)

    return (
        <>
        <WrapperStyled>
            <div>
                <TopLogoComponent survey_no={survey_no}/>
                {
                    !backdrop.show && (
                        <>
                        {
                            questions.map((question, index) => {
                                return (
                                    <QuestionStyled  key={question._question.survey_question_no}>
                                        <SurveyLiveCautionComponent target_question_no={question._question.survey_question_no}/>
                                        <SurveyLiveQuestionComponent 
                                            reply={getReply({replys, question})}
                                            replyComment={getComment({replyComments, question})}
                                            mbox={mbox}
                                            _question={question._question}
                                            _modules={question._modules}
                                            _answers={typeof question._answers === 'object' ? question._answers : []}
                                            _option={question._option}
                                        />
                                    </QuestionStyled>
                                )
                            })
                        }
                    
                        </>
                    )
                
                }
                <div style={{display: backdrop.show ? 'none' : 'block'}}>
                    <QuestionStyled>
                        <PageMultiComponent isClassicButton={true} />
                    </QuestionStyled>
                </div>
            </div>
        </WrapperStyled>        
        <SurveyLivePageMultiComponent/>
        </>       
    )
}