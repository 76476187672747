import { useContext } from 'react'
import { useLocation } from 'react-router'
import { useIntl } from 'react-intl'
import { isMobile } from 'react-device-detect'
import PropsContext from 'context/props'
import AccessMethodContext from 'context/accessMethod'
import oc from 'open-color'
import { Method } from 'gql/jump'
import { makeStyles } from '@material-ui/core/styles'
import { MobileStepper, Box } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { withBreakPoints } from 'hoc'
import { PageContext } from 'container/Survey/Live/Multi'
import { isOffline } from './Restart'
import { isFreeLogo } from './FreeLogo'
import BottomShadowComponent from './BottomShadow'
import ClassicButtonComponent from './ClassicButton'
import SubmitBottomComponent from './SubmitBottom'

const useProgressStyles = makeStyles(theme => ({
    root: props => {
        const { font_family } = props

        return {
            flexGrow: 1,
            fontSize: '16px',
            fontFamily: font_family,
            margin: '0 auto',
            maxWidth: 720
        }
    },
    dots: (props) => {
        const { progress } = props

        return {
            maxWidth: 'calc(100% - 2em)',
            minWidth: 1,
            overflowY: 'auto',
            margin: '0 auto',
            marginLeft: '1em',
            marginRight: '1em',
            display: progress === 'nothing' ? 'none' : 'flex',
            flexWrap: 'wrap',
            gap: '.3em',
            alignContent: 'center'
        }
    }
}))

const useStyles = makeStyles(theme => ({
    wrapper: {
        left: 0,
        width: '100%',
        position: 'fixed',
        background: 'transparent',
        zIndex: 10,
        [theme.breakpoints.down('md')]: {
            /**
             * 프리로고나 오프라인 버튼이 있으면 페이지 버튼의 bottom 위치를 올린다.
             * @param {offline} props 
             * @returns number
             */
            bottom: props => props.offline ? 39 : 0
        },
        [theme.breakpoints.up('md')]: {
            bottom: 0
        }
    },
    buttonSubmitLeft: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: 32,
        paddingLeft: 10,
        paddingRight: 20,
        cursor: 'pointer',
        background: 'rgba(150, 150, 150, .1)',
        transition: 'all .3s',
        borderRadius: 5,
        '&:hover': {
            background: !isMobile ? 'rgba(0, 0, 0, .1)' : 'rgba(150, 150, 150, .1)'
        }
    },
    buttonLeft: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: 32,
        paddingLeft: 10,
        paddingRight: 20,
        cursor: 'pointer',
        transition: 'all .3s',
        borderRadius: 5,
        '&:hover': {
            background: !isMobile ? 'rgba(0, 0, 0, .1)' : 'transparent'
        }
    },
    buttonRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: 32,
        paddingRight: 10,
        paddingLeft: 20,
        cursor: 'pointer',
        transition: 'all .3s',
        borderRadius: 5,
        '&:hover': {
            background: !isMobile ? 'rgba(0, 0, 0, .1)' : 'transparent'
        }
    },
    disabledButton: {
        display: 'flex',
        width: 50,
        height: 32,
    },
    typography: props => {
        const { font_family }  = props
        return {
            fontFamily: font_family,
            fontWeight: 900
        }
    }
}))

export const getIsEnd = (props) => {
    const { steps, activeStep, method } = props

    return ((steps - 1 === activeStep) && (method === Method.End || !method))
}

/**
 * 
 * @param {type: 'one' | 'multi', oneMessage: '1문1답 메세지'} props 
 * @returns 
 */
function PageComponent(props) {
    const { type, oneMessage, isClassicButton, position='center', steps, activeStep, startUsed, onNext, onBack, breakpoints } = props

    const { xl, md, sm, xs } = breakpoints

    const { mbox, design, levels, page } = useContext(PropsContext)
    const { mode } = useContext(AccessMethodContext)
    const { target } = useContext(PageContext)

    const { formatMessage: f } = useIntl()

    const location = useLocation()
    const { pathname } = location

    let giudeWidth = '100%'

    // 1분 1답 우측 이미지 보여주기 처리
    if (xl && !md && !sm && !xs && position === 'left') giudeWidth = '50%'

    const classesProgress = useProgressStyles({ progress: mbox.progress, font_family: design.font_family, position })
    const classes = useStyles({ font_family: design.font_family, offline: isOffline(pathname) || isFreeLogo(mode, levels)})

    const boxStyles = {
        width: giudeWidth, 
        background: oc.gray[0]
    }

    // 1번째 페이지에서 시작글이 있을경우 이전버튼활성화하고 시작글로 가게한다 (-1로 정한다)
    const activeStepPrev = (startUsed &&  activeStep) === 0 ? -1 : activeStep

    // 이전으로 가기 막기 , 메시지
    const disabled = activeStepPrev === 0 || mbox.prev_button

    const isHdieBackButton = ((activeStep === 0 && !startUsed) || disabled) ? true : false
    
    let nextMessage = f({id: `component.Survey.Live.Page.next`})
    let isEnd = false

    if (getIsEnd({ steps, activeStep, method: target.method })) {
        nextMessage = f({id: `component.Survey.Live.Page.end`})
        isEnd = true
    }

    // 클래식 버튼일경우
    if (isClassicButton) return (
        <ClassicButtonComponent 
            isClassicButton={true}
            isHdieBackButton={isHdieBackButton}
            fontFamily={design.font_family}
            survey_no={mbox.survey_no} 
            page={page} 
            message={nextMessage} 
            activeStep={activeStep} 
            activeStepPrev={activeStepPrev}
            classesPage={classes}
            classesProgress={classesProgress}
            boxStyles={boxStyles}
            onNext={onNext}
            onBack={onBack}
        />
    )
    
    return (
        <Box className={classes.wrapper}>
            <div style={{ width: giudeWidth }}>
                <BottomShadowComponent isSplit={giudeWidth === '50%'}/>
            </div>
            {
                isEnd ? (
                    <>
                    {
                        type === 'multi' ? (
                            <ClassicButtonComponent 
                                isClassicButton={false} // 해당 버튼만 차이가 있다.
                                isHdieBackButton={isHdieBackButton}
                                fontFamily={design.font_family}
                                survey_no={mbox.survey_no} 
                                page={page} 
                                message={nextMessage} 
                                activeStep={activeStep} 
                                activeStepPrev={activeStepPrev}
                                classesPage={classes}
                                classesProgress={classesProgress}
                                boxStyles={boxStyles}
                                onNext={onNext}
                                onBack={onBack}
                            />
                        ) : (
                            <SubmitBottomComponent
                                isHdieBackButton={isHdieBackButton}
                                fontFamily={design.font_family}
                                message={oneMessage || f({id: `component.Survey.Live.Page.end`})} 
                                activeStep={activeStep} 
                                activeStepPrev={activeStepPrev} 
                                classesPage={classes} 
                                classesProgress={classesProgress} 
                                boxStyles={boxStyles}
                                onNext={onNext} 
                                onBack={onBack}
                            />
                        )
                    }
                    </>
                    
                ) : (
                    <div style={boxStyles}>
                        <MobileStepper
                        variant={(mbox.progress === 'nothing' || !mbox.progress) ? 'dots' : mbox.progress} // variant는 nothing이 없어서 걍 임의로 dots 준거다 의미없음
                        steps={steps}
                        position="static"
                        activeStep={activeStep}
                        classes={classesProgress}
                        nextButton={
                            <div className={classes.buttonRight} onClick={() => onNext(activeStep)}>
                                <ArrowForwardIcon style={{ color: oc.gray[7] }} />
                            </div>
                        }
                        backButton={
                            <>
                            {
                                // 시작글이 없고, 첫문항페이지 라면 이전버튼을 필요가 엄따
                                ((activeStep === 0 && !startUsed) || disabled) ? (
                                    <div className={classes.buttonLeft} />                      
                                ) : (
                                    <div className={classes.buttonLeft} onClick={() => onBack(activeStepPrev)}>
                                        <ArrowBackIcon style={{ color: oc.gray[7] }} />
                                    </div>
                                )
                            }
                            </>
                        }
                        />
                    </div>
                ) 
            }
           
          
        </Box>
    )
}

export default withBreakPoints(PageComponent)