import { memo, useContext } from 'react'
import { useIntl } from 'react-intl'
import DoneIcon from '@material-ui/icons/Done'
import { PageContext } from 'container/Survey/Live/Multi'
import Button from 'styled/Custom/Button'
import { getIsEnd } from './Page'

interface Props {
    isOneButton: boolean;
    steps?: number;
    activeStep?: number;
    message: string;
    onClick: (a?: any) => void;
}

function OneButtonComponent(props: Props) {
    const { isOneButton, steps, activeStep, message, onClick } = props

    const { target } = useContext(PageContext)

    const { formatMessage: f } = useIntl()

    if (!isOneButton) return null

    let newMessage = ''

    if (typeof steps === 'number' && typeof activeStep === 'number') {
        if (!message && getIsEnd({ steps, activeStep, method: target.method })) {
            newMessage = f({id: `component.Survey.Live.Page.end`})
        }
    }

    if (!newMessage) newMessage = message || f({id: 'component.Survey.Live.One.button'})

    return (
        <Button size="medium" startIcon={<DoneIcon/>} onClick={onClick}>{newMessage}</Button>
    )
}

export default memo(OneButtonComponent)