import { SurveyModulesComponent } from 'component'
import Question from 'styled/Survey/Question'
import Module from 'styled/Survey/Module'
import FileAndPictureComponent from '../Modules/FileAndPicture'
import OneButtonComponent from './OneButton'

const Component = (props) => {
    const { steps, activeStep, _question, mbox, _option={required: false}, onPageNextOne } = props
    const { img_src, img_poster, img_position, img_name, confirm } = _question

    // 멀티일경우 무조건 포지션은 스택이다
    const _img_position = mbox.method === 'multi' ? 'stack' : img_position

    const isOneButton = typeof onPageNextOne === 'function' && mbox.method === 'one'

    return (
        <SurveyModulesComponent {..._question} required={_option.required} mbox={mbox} isOneButton={isOneButton}>
            <div className="question">
                <Question question={_question.question} mark_required={mbox.mark_required} />
            </div>
            <div className="line"></div>
            <div className="module">
                <Module {...props}/>
            </div>
            <div className="image">
                <FileAndPictureComponent
                    method={mbox.method} 
                    img_src={img_src}
                    img_position={_img_position} 
                    img_poster={img_poster}
                    img_name={img_name}
                />
            </div>
            <div className="oneButton">
                <OneButtonComponent isOneButton={isOneButton} steps={steps} activeStep={activeStep} message={confirm} onClick={onPageNextOne} />
            </div>
        </SurveyModulesComponent>  
    )
}

export default (Component)