/**
 * 클래식 버튼에서 사용되는 다음 버튼
 */
import { memo, useEffect, useState } from 'react'
import { SurveyPageClassicButtonProps } from 'gql/survey_page'
import DoneIcon from '@material-ui/icons/Done'
import Button from 'styled/Custom/Button'
import withSurveyFetchData from 'hoc/Survey/FetchData'
import SubmitBottomComponent from './SubmitBottom'

interface Props {
    isClassicButton: boolean;
    isHdieBackButton: boolean;
    fontFamily: string;
    page: number;
    message: string;
    activeStep: number;
    activeStepPrev: number;
    classesPage: any;
    classesProgress: any;
    boxStyles: any;
    onNext: (activeStep: number) => void;
    onBack: (activeStepPrev: number) => void;
    propsFetchData: {
        pageClassicButtons: SurveyPageClassicButtonProps[];
        refetchPageClassicButtons: () => void;
    }
}

function ClassicButtonComponent(props: Props) {
    const { isClassicButton, isHdieBackButton, fontFamily, page, message, activeStep, activeStepPrev, classesPage, classesProgress, boxStyles, onNext, onBack, propsFetchData } = props
    const { pageClassicButtons, refetchPageClassicButtons } = propsFetchData

    const [ mount, setMount ] = useState(false)

    // 커스텀 메세지가 있다면 적용 아니면 디폴트
    let newMessage = message
    if (pageClassicButtons.length > 0) {
        const is = pageClassicButtons.find(c => c.survey_page_no === page)
        if (is) newMessage = is.classic_button_name
    }

    useEffect(() => {
        if (!mount && process.env.REACT_APP_ADMIN_DOMAIN === window.location.hostname) {
            setMount(true)
            refetchPageClassicButtons()
        }
    }, [mount, refetchPageClassicButtons])

    if (isClassicButton) {
        return <Button style={{ marginTop: 10 }} size="medium" startIcon={<DoneIcon/>} onClick={() => onNext(activeStep)}>{newMessage || message}</Button>
    }

    return (
        <SubmitBottomComponent 
            isHdieBackButton={isHdieBackButton}
            fontFamily={fontFamily}
            message={newMessage || message} 
            activeStep={activeStep} 
            activeStepPrev={activeStepPrev} 
            classesPage={classesPage} 
            classesProgress={classesProgress} 
            boxStyles={boxStyles}
            onNext={onNext} 
            onBack={onBack}
        />
    )

}

const fetchDatas = {
    pageClassicButtons: true,
    _fetchPolicy: {
        pageClassicButtons: 'cache-first'
    }
}
export default withSurveyFetchData('live')(fetchDatas)(false)(memo(ClassicButtonComponent))