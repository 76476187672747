import { useContext } from 'react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import PropsContext from 'context/props'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    message: props => {
        const { font_family } = props

        return {
            fontFamily: font_family
        }
    },
    caption: props => {
        const { font_family } = props

        return {
            marginBottom: 10,
            fontSize: 12,
            fontFamily: font_family
        }
    }
})

const WrapperStyled = styled.div`
    color: ${props => `rgba(${props.color})`};
`

export default () => {
    const { design } = useContext(PropsContext)
    const { answer, font_family } = design

    const classes = useStyles({ font_family })

    const { formatMessage: f } = useIntl()

    return (
        <WrapperStyled color={answer}>
            <Typography variant="body2" className={classes.message}>{f({id: 'component.Survey.Live.Drag.message'})}</Typography>
            <Typography className={classes.caption}>{f({id: 'component.Survey.Live.Drag.message2'})}</Typography>
        </WrapperStyled>
    )
}