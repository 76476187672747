import { useContext } from 'react'
import { useSelector } from 'react-redux'
import PropsContext from 'context/props'
import styled from 'styled-components'
import { Collapse } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import { useIntl } from 'react-intl'
import { Element } from 'react-scroll'

const useStyles = makeStyles({
    filledInfo: {
        background: 'black',
    },
    typography: props => {
        const { font_family } = props

        return {
            fontFamily: font_family,
            wordBreak: 'break-all'
        }
    }
})

const WrapperStyled = styled.div`
    margin-bottom: 1em;
`

export default (props) => {
    const { target_question_no } = props
    const { scrollerObj={target: ''}, design } = useContext(PropsContext)

    const caution = useSelector(state => state.surveyLiveCaution)
  

    const classes = useStyles({ font_family: design.font_family })

    const { formatMessage: f } = useIntl()
    
    const message = caution.message ? caution.message : caution.code ? f({id: `component.Survey.Live.Caution.${caution.code}`}) : ''
    
    return (
        <Element name={target_question_no === caution.survey_question_no ? scrollerObj.target : ''}>
            <WrapperStyled>
                <Collapse in={target_question_no === caution.survey_question_no}>
                    <Alert severity="error" className={classes.filledInfo} elevation={1} variant="filled">
                        <span className={classes.typography}>{message}</span>
                    </Alert>
                </Collapse>
            </WrapperStyled>
        </Element>
        
    )
}