// 제출 버튼 (클래식, 1문 1답 모두사용)

import { memo } from 'react'
import oc from 'open-color'
import { isMobile } from 'react-device-detect'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

interface Props {
    isHdieBackButton: boolean;
    fontFamily: string;
    message: string;
    activeStep: number;
    activeStepPrev: number;
    classesPage: any;
    classesProgress: any;
    boxStyles: any;
    onNext: (activeStep: number) => void;
    onBack: (activeStepPrev: number) => void;
}

const useStyles = makeStyles(theme => ({
    submit: (props: { nextWidth: string }) => {
        const { nextWidth } = props
        
        return {
            textAlign: 'center', 
            background: 'rgba(150, 150, 150, .1)',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            wordBreak: 'break-all',
            fontSize: 15,
            height: 32,
            padding: '5px 7px 0 5px',
            borderRadius: 5,
            fontWeight: 700,
            cursor: 'pointer',
            width: nextWidth,
            '&:hover': {
                background: !isMobile ? 'rgba(0, 0, 0, .1)' : 'rgba(150, 150, 150, .1)'
            }
        }
       
    }
}))

function SubmitBottomComponent(props: Props) {
    const { isHdieBackButton, fontFamily, message, activeStep, activeStepPrev, classesPage, classesProgress, boxStyles, onNext, onBack } = props

    const nextWidth = isHdieBackButton ? '100%' : '93%'

    const classes = useStyles({ nextWidth })

    return (
        <div style={boxStyles}>
            <div 
                className={classesProgress.root} 
                style={{ 
                    paddingTop: 1, 
                    paddingLeft: 8, 
                    paddingRight: 8, 
                    height: 48, 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'space-between', 
                    gap: 10
                }}>
                {
                    !isHdieBackButton && (
                        <div className={classesPage.buttonSubmitLeft} onClick={() => onBack(activeStepPrev)}>
                            <ArrowBackIcon style={{ color: oc.gray[7] }} />
                        </div>
                    )
                }

                <div className={classes.submit} onClick={() => onNext(activeStep)}>
                    <span style={{ fontFamily }}>{message}</span>
                </div>
            </div>
        </div>
    )
}

export default memo(SubmitBottomComponent)