import { useContext } from 'react'
import AccessMethodContext from 'context/accessMethod'
import BeforeUnload from 'styled/BeforeUnload'

// 세이브 일때는 빈값으로 보여준다
import MultiQuestionsComponent from './MultiQuestions'

export default (props) => {
    const access = useContext(AccessMethodContext)

    return (
        <>
        <MultiQuestionsComponent/>
        {access.beforeUnload === true && <BeforeUnload/>}
        </>
    )
}