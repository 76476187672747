import { useContext } from 'react'
import PropsContext from 'context/props'
import AccessMethodContext from 'context/accessMethod'
import { getReply, getComment } from 'utils/survey'
import styled from 'styled-components'
import { SurveyLiveCautionOneComponent, SurveyLiveQuestionComponent } from 'component'
import OneStyled, { QuestionStyled } from 'styled/Survey/Live/One'
import BeforeUnload from 'styled/BeforeUnload'

const CautionStyled = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
`

let prevIsButton = false
const Component = (props) => {
    const { mbox, questions, currentIndex, replys, replyComments, onNext, isJust } = useContext(PropsContext)
    const access = useContext(AccessMethodContext)

    const question = questions[currentIndex] || { _question: {}, _modules: [], _answers: [], _option: {} }
    const { _question, __typename } = question
    const { img_src, img_position } = _question

    const _getReply = getReply({replys, question})
    const _getComment = getComment({replyComments, question})

    // 전체 문항 수
    const steps = questions.length

    // 현재 인덱스
    const activeStep = currentIndex

    // isJust(바로가기) 기능이 false라면 버튼 보여준다.
    // 응답후 이전으로 가면 버튼이 보이는데, 그상태에서 선택하면 다시 버튼이 안보여줄 필요까진 없을거같다. 화면이 움직여서 산만함
    // 값이 없으면 묻고 따지지도 않고 버튼 가려
    // 설면문은 무조건~
    let isButton = !isJust
    if (prevIsButton) isButton = true
    if (_getReply.length === 0) isButton = false
    if (__typename === 'Module_00') isButton = true

    prevIsButton = isButton

    return (
        <>
        <CautionStyled>
            <SurveyLiveCautionOneComponent target_question_no={_question.survey_question_no}/>
        </CautionStyled>
        <OneStyled no={_question.survey_question_no} img_src={img_src} img_position={img_position}>
            <QuestionStyled>
                <div>
                    <div>
                        <SurveyLiveQuestionComponent 
                            key={question._question.survey_question_no} 
                            reply={_getReply}
                            replyComment={_getComment}
                            mbox={mbox}
                            steps={steps}
                            activeStep={activeStep}
                            _question={question._question}
                            _modules={question._modules}
                            _answers={typeof question._answers === 'object' ? question._answers : []}
                            _option={question._option}
                            onPageNextOne={
                                isButton && onNext
                            }
                        />
                    </div>
                </div>
            </QuestionStyled>
        </OneStyled>
        {access.beforeUnload === true && <BeforeUnload/>}
        </>
    )
}

export default Component