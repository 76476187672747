import { useContext } from 'react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import PropsContext from 'context/props'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const WrapperStyled = styled.div`
    color: ${props => `rgba(${props.color})`};
`

const MyTypography = withStyles({
    root: props => {
        const { font_family } = props

        return {
            fontFamily: font_family
        }
    }
})(props => <Typography gutterBottom variant="body2" {...props}/>)


export default ({module_type, required_start, required_end}) => {
    const { design } = useContext(PropsContext)
    const { answer, font_family } = design
    const { formatMessage: f } = useIntl()


    const message = required_start === required_end ? f({id: `component.Survey.Live.Required.exactly.${module_type}`}, {exactly: required_start})
                                                    : f({id: `component.Survey.Live.Required.arrange.${module_type}`}, {start: required_start, end: required_end})
    return (
        <WrapperStyled color={answer}>
            <MyTypography font_family={font_family}>{message}</MyTypography>
        </WrapperStyled>
    )
}