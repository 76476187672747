import { useContext } from 'react'
import PropsContext from 'context/props'
import { SurveyLivePageComponent } from 'component'

export default () => {
    const { questions, currentIndex, startUsed, onNext, onPrev } = useContext(PropsContext)

    let position = 'center'

    const question = questions[currentIndex]

    let confirmMessage = ''
    if (question) {
        const { _question } = question
        const { img_position, confirm } = _question

        confirmMessage = confirm

        if (img_position === 'split' || img_position === 'float') position = 'left'
    }

    // 페이지 길이
    const steps = questions.length

    const activeStep = currentIndex

    return (
        <SurveyLivePageComponent
            type="one"
            position={position}
            steps={steps} 
            oneMessage={confirmMessage}
            activeStep={activeStep} 
            startUsed={startUsed}
            onNext={onNext}
            onBack={onPrev}
        />
    )
}
