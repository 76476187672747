import { memo } from 'react'
import { useLocation } from 'react-router'
import oc from 'open-color'
import { Levels } from 'ts-policy'
import { Mode } from 'gql/survey_analysis_summary'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import FaceLogo from 'styled/FaceLogoNew'
import useLandingPage from 'hooks/useLandingPage'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'
import { moveOutLink } from 'component/Survey/Modules/Ending'
import { isOffline } from './Restart'

interface Props {
    levels: Levels;
    mode: string;
    isPage: boolean;
    breakpoints: BreakPointsProps;
}
/*
'Noto Sans KR':f({id: 'styled.Survey.FontFamilys.fonts.1'}),
'Nanum Gothic':f({id: 'styled.Survey.FontFamilys.fonts.2'}),
'NanumBarunGothic':f({id: 'styled.Survey.FontFamilys.fonts.3'}),
'NanumSquareRound':f({id: 'styled.Survey.FontFamilys.fonts.4'}),
'MalgunGothic':f({id: 'styled.Survey.FontFamilys.fonts.5'}),
'Arita-buri-SemiBold':f({id: 'styled.Survey.FontFamilys.fonts.6'}),
'Arita-dotum-Medium':f({id: 'styled.Survey.FontFamilys.fonts.7'}),
'paybooc-Bold':f({id: 'styled.Survey.FontFamilys.fonts.8'}),
'BMJUA':f({id: 'styled.Survey.FontFamilys.fonts.9'}),
'BMEULJIRO':f({id: 'styled.Survey.FontFamilys.fonts.10'}),
'BMEuljiro10yearslater':f({id: 'styled.Survey.FontFamilys.fonts.11'}),
'yg-jalnan':f({id: 'styled.Survey.FontFamilys.fonts.12'}),
'HangeulNuri-Bold':f({id: 'styled.Survey.FontFamilys.fonts.13'}),
'Roboto':f({id: 'styled.Survey.FontFamilys.fonts.14'}),
'Arial':f({id: 'styled.Survey.FontFamilys.fonts.15'}),
'Verdana':f({id: 'styled.Survey.FontFamilys.fonts.16'}),
'Georgia':f({id: 'styled.Survey.FontFamilys.fonts.17'}),
'Segoe UI':f({id: 'styled.Survey.FontFamilys.fonts.18'})*/

const useStyles = makeStyles(theme => ({
    button: () => {
        return {
            backgroundColor: 'transparent', 
            position: 'fixed', 
            fontFamily: 'yg-jalnan',
            zIndex: 10,
            right: 3, 
            fontSize: 15,
            bottom: 3, 
            fontWeight: 900, 
            color: theme.palette.primary.dark, 
            border: `1px solid ${theme.palette.primary.dark}`,
            backdropFilter: 'blur(10px)',
            [theme.breakpoints.down('md')]: {
                width: '100%',
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 'unset',
                top: 'unset',
                border: 'none',
                borderRadius: 0
            }
        }
    },
    logo1: {
        color: theme.palette.primary.light,
        cursor: 'pointer'
    },
    logo2: {
        fontSize: 18, 
        fontWeight: 900, 
        color: theme.palette.primary.dark
    }
}))


// 로고 판단 여부
export const isFreeLogo = (mode: Mode, levels: Levels) => {
    if (mode !== 'live') return false
    if (levels !== Levels.Level0 && levels !== Levels.Level1000) return false

    return true
}

function FreeLogoComponent(props: Props) {
    const { levels, mode, isPage, breakpoints } = props
    const { md } = breakpoints

    const getLandingPage = useLandingPage()

    const location = useLocation()
    const { pathname } = location

    const classes = useStyles()

    // 오프라인 링크면서, 모바일에서는 안보여준다 공간이 없다..
    if (isOffline(pathname) && md) return null 

    // 라이브에서만 보여지고, 무료 플랜, 베이직 플랜에서만 보여진다
    if (!isFreeLogo(mode, levels)) return null

    const variant = md ? 'text' : 'contained'

    const style: { backgroundColor: string, cursor: string, borderTop?: string } = { cursor: 'default', backgroundColor: 'rgba(248, 249, 250, .4)' } 
    
    if (md && isPage) {
        style['backgroundColor'] = oc.gray[0]
        style['borderTop'] = `1px dashed ${oc.gray[1]}`
    }

    return (
        <Button 
            size="small" 
            variant={variant} 
            disableRipple
            style={style}
            className={`${classes.button}`}
        >
            <span className={classes.logo1} onClick={() => moveOutLink(getLandingPage())}>Made with <span className={classes.logo2}>Listovey</span></span>
            <FaceLogo onClick={() => moveOutLink(getLandingPage())} style={{ width: 25, height: 25, marginLeft: 8, cursor: 'pointer' }}/>

        </Button>
    )
}

export default memo(withBreakPoints(FreeLogoComponent))