import { useState, useEffect } from 'react'
import { isSafari } from 'react-device-detect'
import { Slide, Fade, Box } from '@material-ui/core'

let timer = null
export default (props) => {
    const { slide, onCallbackSlide, children } = props

    const [ disabled, setDisabled ] = useState(false)

    useEffect(() => {
        return () => {
            clearTimeout(timer)
        }
    }, [])

    if (isSafari) {
        return (
            <>
            <Fade
                in={slide.do} 
                onExited={() => {
                    setDisabled(true)
                    return onCallbackSlide(slide.direction)
                }}
                onEntered={() => {
                    timer = setTimeout(() => {
                        setDisabled(false)
                    }, 300)
                }}
            >
                {children}
            </Fade>
            <Box style={{ position: 'fixed', display: disabled ? 'block' : 'none', top: 0, right: 0, bottom: 0, left: 0, background: 'transparent', zIndex: 99999 }}/>
            </>
        )
    }

    return (
        <>
        <Slide
            direction={slide.direction} 
            in={slide.do} 
            onExited={() => {
                setDisabled(true)
                return onCallbackSlide(slide.direction)
            }}
            onEntered={() => {
                timer = setTimeout(() => {
                    setDisabled(false)
                }, 300)
            }}
        >
            {children}
        </Slide>
        <Box style={{ position: 'fixed', display: disabled ? 'block' : 'none', top: 0, right: 0, bottom: 0, left: 0, background: 'transparent', zIndex: 99999 }}/>
        </>
    )
}
 