import { useContext, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { HIDE as CAUTIONHIDE } from 'reducer/survey/live/caution'
import PropsContext from 'context/props'
import styled from 'styled-components'
import { Collapse } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import { useIntl } from 'react-intl'
import { Element } from 'react-scroll'

const useStyles = makeStyles({
    filledInfo: {
        background: 'black',
        borderRadius: 0
    },
    typography: props => {
        const { font_family } = props

        return {
            fontFamily: font_family,
            wordBreak: 'break-all'
        }
    }
})

const WrapperStyled = styled.div`

`

export default (props) => {
    const { target_question_no } = props
    const { scrollerObj={target: ''}, design  } = useContext(PropsContext)

    const dispatch = useDispatch()

    const caution = useSelector(state => state.surveyLiveCaution)

    const classes = useStyles({ font_family: design.font_family })

    const { formatMessage: f } = useIntl()
    
    const message = caution.message ? caution.message : caution.code ? f({id: `component.Survey.Live.Caution.${caution.code}`}) : ''
    
    useEffect(() => {
        if (caution.survey_question_no) {
            setTimeout(() => {
                dispatch({ type: CAUTIONHIDE })
            }, 2000)
        }
    }, [caution, dispatch])
    return (
        <Element name={target_question_no === caution.survey_question_no ? scrollerObj.target : ''}>
            <WrapperStyled>
                <Collapse in={target_question_no === caution.survey_question_no}>
                    <Alert severity="info" className={classes.filledInfo} elevation={1} variant="filled">
                        <span className={classes.typography}>{message}</span>
                    </Alert>
                </Collapse>
            </WrapperStyled>
        </Element>
        
    )
}