/**
 * 탑로고 이미지
 */

import { memo } from 'react'
import { handleDisabledRightClick } from 'ts-utils'

interface Props {
    src: string;
    height: number
}

function TopLogoImgComponent(props: Props) {
    const { src, height } = props

    const styles = {
        maxWidth: '100%',
        height,
        borderRadius: 3
    }

    return <img src={src} alt="리스토베이" style={styles} onContextMenu={handleDisabledRightClick}/>
}

export default memo(TopLogoImgComponent)