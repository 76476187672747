import { useContext, useEffect } from 'react'
import PropsContext from 'context/props'
import { Box }from '@material-ui/core'
import { getBackground } from 'utils/survey'
import { Element } from 'react-scroll'
import Dialog from 'styled/Survey/Live/Dialog'

const Component = (props) => {
    const { design, children, refetchMboxDesign } = props
    const { scrollerObj, open } = useContext(PropsContext)
    const style = getBackground(design)

    // 백그라운드 디자인 설정
    const _style = {
        ...scrollerObj.style,
        ...style,
        backgroundAttachment: 'scroll',
    }

    useEffect(() => {
        if (open) refetchMboxDesign()
    }, [open, refetchMboxDesign])

    return (
        <Dialog open={true} onClose={() => {}}>
            <Element id={scrollerObj.id} style={_style}>
                <Element name={scrollerObj.targetTop}>
                    <div></div>
                </Element>
                <Box>
                    {children}
                </Box>
            </Element>
        </Dialog>
    )
}

export default Component