/**
 * 아래 내용이 있을경우 흐릿하게 처리 (밑에 내용이 더있다는 표현을 내기 위해)
 */
import { memo, useContext, useState, useEffect } from 'react'
import PropsContext from 'context/props'
import Box from '@material-ui/core/Box'
import { makeStyles, Theme } from '@material-ui/core/styles'

interface Props {
    isSplit: boolean;
}

interface StylesProps {
    background_color: string;
    isBlur: boolean;
}

const useStyles = makeStyles<Theme, StylesProps>(theme => ({
    box: (props) => {
        const { background_color, isBlur } = props

        const [r, g, b] = background_color.split(',')

        const boxShadowColor = (Number(r) <= 50 && Number(g) <= 50 && Number(b) <= 50) ? 'rgba(255, 255, 255, .2)' : 'rgba(75, 75, 75, .1)'

        return {
            display: isBlur ? 'block' : 'none',
            width: '100%',
            height: 20, 
            transition: 'all .3s', 
            backdropFilter: 'blur(2px)',  
            boxShadow: `inset 0px 11px 8px -10px ${boxShadowColor}` 
        }

    }
}))



const setClientHeight = (val: number) => val + 50

function BottomShadowComponent(props: Props) {
   /*
    const { isSplit } = props
    const { scrollerObj, contentID, design } = useContext<any>(PropsContext)
    
    const { background_color } = design
    const [ isBlur, setIsBlur ] = useState(false)


    const classes = useStyles({ background_color, isBlur })

    let clientHeight = 0
    let scrollHeight = 0
    let obj:null | HTMLElement = null

    // 분할잉 경우에는 contentId 사용
    if (isSplit) {
        if (contentID) {
            obj = document.getElementById(contentID)
            if (obj) {
                clientHeight = setClientHeight(obj.clientHeight)
                scrollHeight = obj.scrollHeight
            }
        }
    } else {
        if (scrollerObj) {
            if (scrollerObj.id) {
                obj = document.getElementById(scrollerObj.id)
                if (obj) {
                    clientHeight = setClientHeight(obj.clientHeight)
                    scrollHeight = obj.scrollHeight
                }
            }
        }
    }
    
    useEffect(() => {
        const Do = () => {
            if (!obj) return 

            const clientHeight = setClientHeight(obj.clientHeight)
            const scrollHeight = obj.scrollHeight
            const scrollTop = obj.scrollTop

            setIsBlur(clientHeight + Math.ceil(scrollTop) < scrollHeight)
        }

        if (obj) obj.addEventListener('scroll', Do)
        
        return () => {
            if (obj) obj.removeEventListener('scroll', Do)
        }
    }, [clientHeight, scrollHeight, obj])


    // scrollHeight > clientHeight 이면 스크롤이 있으므로 뿌옇게 처리
    useEffect(() => {
        setIsBlur(scrollHeight > clientHeight)
    }, [scrollHeight, clientHeight])

    return (
        <Box className={classes.box}></Box>
    )
    */

    return null
}

export default memo(BottomShadowComponent)