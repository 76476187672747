// 설문 내용 탑 부분에 로고 위치
import { memo, useContext, useEffect, useRef } from 'react'
import AccessMethodContext from 'context/accessMethod'
import PropsContext from 'context/props'
import { Levels } from 'ts-policy'
import { SurveyMboxCoverProps } from 'gql/survey_mbox_cover'
import { SurveyQuestionModuleProps } from 'gql/survey_question_modules'
import { Method } from 'gql/survey_mbox'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import withSurveyFetchData from 'hoc/Survey/FetchData'
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'
import TopLogoImgComponent from './TopLogoImg'

interface Props {
    img_position?: SurveyQuestionModuleProps['_question']['img_position'];
    propsFetchData: {
        mboxCover: SurveyMboxCoverProps;
    };
    breakpoints: BreakPointsProps;
}

interface UseStylesProps {
    img_position?: Props['img_position'];
    question_top_align: SurveyMboxCoverProps['question_top_align'];
}

const defaultHegiht = 50

const useStyles = makeStyles(theme => ({
    box: (props: UseStylesProps) => {
        const { img_position, question_top_align } = props

        const defaultStyle = { top: 20, paddingLeft: 20, paddingRight: 20 }

        const styles = img_position === 'split' ? { top: 10, paddingLeft: 10, paddingRight: 10 } : defaultStyle

        return {
            position: 'absolute',
            width: '100%',
            maxWidth: 720,
            margin: '0 auto',
            textAlign: question_top_align,
            ...defaultStyle,
            [theme.breakpoints.down('sm')]: {
                ...styles,
                maxWidth: '100%'
            }
        }
    },
    img: {
        maxWidth: '100%',
        height: defaultHegiht,
        borderRadius: 3,
        background: 'transparent'
    }
}))

// 탑로고 보여주는 모드
const possibleMode = [ 'preview', 'previewTs', 'live' ] 

function TopLogoComponent(props: Props) {
    const { img_position='stack', propsFetchData, breakpoints } = props
    const { mboxCover } = propsFetchData
    const { src, used, question_top_used, question_top_align } = mboxCover

    const { sm } = breakpoints

    const { mode } = useContext<any>(AccessMethodContext)
    const { levels, mbox } = useContext<any>(PropsContext)
    const { method } = mbox

    const classes = useStyles({ img_position, question_top_align })

    const refBox = useRef<null | any>(null)

    useEffect(() => {
        if (refBox) {
            if (refBox.current) {
            
                let height:string = `${(defaultHegiht)}px`
                if (method === Method.Multi) {
                    if (sm) {
                        // 모바일에서 이미지 에다가 패딩을 줘야한다.
                        if (img_position === 'float') height = `${(50 + defaultHegiht)}px`
                    } else {
                        // pc 버전에서는 내용에 이미지가 없다
                        if (img_position === 'float' || img_position === 'split') height = `${(30 + defaultHegiht)}px`
                    }
                }
                else {
                    // 클래식에서는 
                    if (sm) {
                        // 시작글 완료글
                        if (img_position === 'float') height = `${(50 + defaultHegiht)}px`
                    } else {
                        if (img_position === 'float' || img_position === 'split') height = `${(35 + defaultHegiht)}px`
                    }
                }
                
                refBox.current.style.height = height
            }
        }

    }, [refBox, img_position, method, sm])

    // 라이브에서는 무료사용자 안보여준다. 
    if (mode === 'live' && levels === Levels.Level0) return null
    
    if (!src) return null

    if (!used || !question_top_used) return null
    
    if (!possibleMode.includes(mode)) return null 

    return (
        <>
            <Box className={classes.box} >
                <TopLogoImgComponent src={src} height={defaultHegiht}/>
            </Box>
            <div ref={refBox}></div>
        </>
    )
}

const args = {
    mboxCover: true,
    _fetchPolicy: {
        mboxCover: 'cache-first'
    }
}
export default withSurveyFetchData('live')(args)(false)(withBreakPoints(memo(TopLogoComponent)))