import { SurveyLiveMultiContainer } from 'container'
import { withSurveyFetchMboxWithRelation, withSurveyFetchQuestions, withSurveyFetchAnalysisUser, withSurveyFetchQuestionsWithJumpMulti } from 'hoc'

const mode = 'live'

const Contents = (props) => (
    <SurveyLiveMultiContainer {...props}/>    
)

export default withSurveyFetchAnalysisUser(mode)
               (withSurveyFetchMboxWithRelation({mode, fetchPolicy: 'cache-first'})(true)
               (withSurveyFetchQuestions(mode)
               (withSurveyFetchQuestionsWithJumpMulti({mode, loading: false})(Contents))))